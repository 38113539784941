import { EventLogType } from "types/EventLogType";
import NumberUtils from "./NumberUtils";

const getPaymentName = (method: string): string => {
  if (!method) return '';
  switch (method) {
    case 'card': return "Credit or debit card";
    case 'alipay': return "Alipay";
    case 'bdo': return "BDO Unibank";
    case 'bpi': return "Bank of the Philippine Islands";
    case 'coins': return "Coins.ph";
    case 'gcash': return "GCash";
    case 'grab': return "Grab Pay";
    case 'metrobank': return "Metrobank";
    case 'paymaya': return "PayMaya";
    case 'pnb': return "Philippine National Bank";
    case 'rcbc': return "Rizal Commercial Banking Corporation";
    case 'unionbank': return "Unionbank";
    case 'unionpay': return "UnionPay"
    case 'wechat': return "WeChat Pay"
    default: return method;
  }
}

const getCardBrandName = (brand: string): string => {
  if (!brand) return '';
  switch (brand) {
    case 'mastercard': return "Mastercard";
    case 'visa': return "Visa";
    case 'amex': return "American Express";
    case 'jcb': return "JCB"
    case 'discover': return "Discover"
    case 'unionpay': return "UnionPay"
    default: return brand;
  }
}

const getEventMessage = (e: EventLogType) => {
  if (e.type === 'request.created' && e.status === 200) {
    return `A payment request for ${NumberUtils.formatCurrency(e.response.total/100, e.response.currency)} ${e.response.currency.toUpperCase()} to ${e.response.customer_name ? e.response.customer_name : e.response.customer_email} was created`
  } else if (e.type === 'request.updated' && e.status === 200) {
    return `${e.response.customer_name ? e.response.customer_name : e.response.customer_email}'s payment request has changed`
  } else if (e.type === 'request.send_email' && e.status === 'success') {
    return `Payment request was sent via email to ${e.payload.recipient}`
  } else if (e.type === 'request.resend_email' && e.status === 'success') {
    return `Resent payment request via email to ${e.payload.recipient}`
  } else if (e.type === 'request.send_sms' && e.status === 'success') {
    return `Payment request was sent via SMS to ${e.payload.recipient}`
  } else if (e.type === 'request.resend_sms' && e.status === 'success') {
    return `Resent payment request via SMS to ${e.payload.recipient}`
  } else if (e.type === 'request.paid' && e.status === 200) {
    return `${e.response.customer_name ? e.response.customer_name : e.response.customer_email}'s payment request for ${NumberUtils.formatCurrency(e.response.total/100, e.response.currency)} was paid`
  } else if (e.type === 'request.voided' && e.status === 200) {
    return `${e.response.customer_name ? e.response.customer_name : e.response.customer_email}'s payment request for ${NumberUtils.formatCurrency(e.response.total/100, e.response.currency)} was voided`
  } else if (e.status === 'error') {
    return ''
  }
}

export default {
  getPaymentName,
  getCardBrandName,
  getEventMessage,
}