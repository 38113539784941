import Currencies from '@constants/Currencies.json'

const getCurrencies = () => {
  try {
    return Currencies.map((c) => {
      return { label: `${c.name} (${c.symbol})`, value: c.code }
    })
  } catch (e) {
    console.log(e)
    return []
  }
}

const getCurrencySymbol = (currencyCode = 'php') => {
  try {
    return Currencies.find((c) => {
      return c.code === currencyCode.toUpperCase()
    })?.symbol
  } catch (e) {
    console.error(e)
    return ''
  }
}

const getCurrencyName = (currencyCode: string) => {
  try {
    return Currencies.find((c) => {
      return c.code === currencyCode.toUpperCase()
    })?.name
  } catch (e) {
    console.error(e)
    return ''
  }
}

const isValidCurrency = (currency: string) => {
  try {
    return (
      Currencies.find((c) => {
        return c.code === currency.toUpperCase()
      }) !== undefined
    )
  } catch (e) {
    console.error(e)
    return false
  }
}

const formatAmount = (
  amount: number,
  decimalCount = 2,
  decimal = '.',
  thousands = ',',
  prefix = ''
) => {
  try {
    decimalCount = Math.abs(decimalCount)
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount

    const negativeSign = amount < 0 ? '-' : ''

    const i = parseInt(
      Math.abs(Number(amount) || 0).toFixed(decimalCount)
    ).toString()
    const j = i.length > 3 ? i.length % 3 : 0

    return (
      negativeSign +
      prefix +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs((amount < 0 ? amount * -1 : amount) - parseInt(i))
            .toFixed(decimalCount)
            .slice(2)
        : '')
    )
  } catch (e) {
    console.log(e)
    return amount.toString()
  }
}

const formatCurrency = (amount: number, currencyCode = 'php') => {
  try {
    if (amount === undefined) amount = 0
    if (currencyCode === '' || currencyCode === undefined)
      return formatAmount(amount)
    const currency = Currencies.find((c) => {
      return c.code === currencyCode.toUpperCase()
    })

    if (currency) {
      return formatAmount(
        amount,
        currency.decimal_digits,
        currency.separator,
        currency.delimiter,
        currency.symbol
      )
    } else {
      return formatAmount(amount)
    }
  } catch (e) {
    console.log(e)
    return formatAmount(amount)
  }
}

const roundOff = (value: number, precision = 0) => {
  try {
    if (value === undefined) value = 0
    const multiplier = Math.pow(10, precision || 0)
    return Math.round(value * multiplier) / multiplier
  } catch (e) {
    console.log(e)
    return ''
  }
}

export default {
  getCurrencies,
  isValidCurrency,
  getCurrencySymbol,
  getCurrencyName,
  formatAmount,
  formatCurrency,
  roundOff,
}
