import { ApisauceInstance, create } from 'apisauce'
import apiMonitor from '@lib/monitor'

export const createApiClient = (secretKey: string): ApisauceInstance => {
  const api = create({
    baseURL: process.env.NEXT_PUBLIC_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${Buffer.from(`${secretKey}:`).toString('base64')}`,
    },
    timeout: 15000,
  })

  api.addMonitor(apiMonitor)

  return api
}

export const createMagpieClient = (secretKey: string): ApisauceInstance => {
  const api = create({
    baseURL: process.env.NEXT_PUBLIC_MAGPIE_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${Buffer.from(`${secretKey}:`).toString('base64')}`,
    },
    timeout: 15000,
  })

  api.addMonitor(apiMonitor)

  return api
}

export const createSessionsClient = (secretKey: string): ApisauceInstance => {
  const api = create({
    baseURL: process.env.NEXT_PUBLIC_MAGPIE_SESSIONS_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${Buffer.from(`${secretKey}:`).toString('base64')}`,
    },
    timeout: 15000,
  })

  api.addMonitor(apiMonitor)

  return api
}
