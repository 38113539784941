/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiResponse } from 'apisauce'

import URIs from './uri'
import {
  createApiClient,
  createMagpieClient,
  createSessionsClient,
} from './clients'
import { SessionsRequestType } from 'types/SessionsType'

const getCharge = async (
  key: string,
  chargeId: string
): Promise<ApiResponse<any, any>> => {
  const api = createMagpieClient(key)
  return await api.get(URIs.CHARGE.replace('$1', chargeId))
}

const getRequest = async (
  key: string,
  requestId: string
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient(key)
  return await api.get(URIs.REQUEST.replace('$1', requestId))
}

const payRequest = async (
  key: string,
  requestId: string,
  chargeId: string
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient(key)
  return await api.post(URIs.REQUEST_PAY.replace('$1', requestId), {
    charge_id: chargeId,
  })
}

const createSession = async (
  key: string,
  params: SessionsRequestType
): Promise<ApiResponse<any, any>> => {
  const api = createSessionsClient(key)
  return await api.post(URIs.SESSIONS, params)
}

const getSession = async (
  key: string,
  sessionId: string
): Promise<ApiResponse<any, any>> => {
  const api = createSessionsClient(key)
  return await api.get(URIs.SESSION.replace('$1', sessionId))
}

export default {
  getCharge,
  getRequest,
  createSession,
  getSession,
  payRequest,
}
